import { BaseTexture, Color4, Mesh, Ray, Vector3 } from "@babylonjs/core";
import stickers from './stickers.json';

export function is_dev(){
    return process.env.NODE_ENV === 'development';
}

export function lerp(x: number, y: number, t01: number){
    return x * (1 - t01) + y * t01;
}

export function unlerp(x: number, y: number, t: number){
    return (t - x) / (y - x);
}


export enum epaint{    
    stripe,
    chequered,
    mixed,
}

export enum emodel{
    sportsguard,    
    splint,
}

export enum esplint{
    splint,
    ferrar,
    gelb,
    gelb_split,
    NTI,
    kois,    
}

export enum ecover{
    none,
    short,
    long,
}

export enum ethickness{
    thin,
    thick, 
}

export enum ebite{
    none,
    molar,
    all,
}

export enum eupdown{
    upper,
    lower,
    both,
}

export enum ematerial{
    thermo,
    hard,
    dual,
    blue3d,
    clear3d,
}

export type mrcolor4 = {
    m: number,
    r: number,
    c: Color4,
}

export function compare_mrcolor4(x: mrcolor4, y: mrcolor4){
    return x.m === y.m
        && x.r === y.r
        && x.c.r === y.c.r
        && x.c.g === y.c.g
        && x.c.b === y.c.b
        && x.c.a === y.c.a;
}

export enum eball_clasp{
    none,
    single,
    double,
    triple,
}

function mrc(r01: number, g01: number, b01: number, a01: number, metallic01 = 0, roughness01 = 0): mrcolor4{
    return {
        m: metallic01,
        r: roughness01,
        c: new Color4(r01, g01, b01, a01),
    }
}

export type color_option = {icon: string, mrc4: mrcolor4}

export const color_options: {[k: string]: color_option} = {
    white: {icon: 'color_01', mrc4: mrc(1, 1, 1, 1)},
    black: {icon: 'color_02', mrc4: mrc(0, 0, 0, 1)},
    clear: {icon: 'color_03', mrc4: mrc(1, 1, 1, 1, 1, 0)},
    blue: {icon: 'color_04', mrc4: mrc(0, 0, 1, 1)},
    purple: {icon: 'color_05', mrc4: mrc(0.5, 0, 0.5, 1)},
    pink: {icon: 'color_06', mrc4: mrc(1, 0.43, 0.6, 1)},
    red: {icon: 'color_07', mrc4: mrc(1, 0, 0, 1)},
    orange: {icon: 'color_08', mrc4: mrc(1, 0.5, 0, 1)},
    yellow: {icon: 'color_09', mrc4: mrc(1, 1, 0, 1)},
    green: {icon: 'color_10', mrc4: mrc(0, 1, 0, 1)},
    navy: {icon: 'color_11', mrc4: mrc(0, 0, 0.5, 1)},
};

const { clear, ...dracular_color_options } = color_options;
export { dracular_color_options };

// Export color options for dracular mode first and third color selection
export const dracular_first_third_color_options = color_options;

// Export color options for dracular mode second color selection (without clear option)
export const dracular_second_color_options = dracular_color_options;

export const {clear: clearOption, ...lettering_color_options} = color_options;

export type decal = {
    tex: BaseTexture,
    normal: Vector3, 
    position: Vector3,
    angle_rad: number,
    scale: number,
    hmirror: boolean,
    ray: Ray,
    mesh: Mesh,
    decal : edecal,
    lettering_text: string,
    lettering_color: mrcolor4,
}

export type model_paint_state = {
    model: emodel,
    sportsguard_dracular: boolean
    splint_type: esplint,
    cover: ecover,
    thickness: ethickness,
    bite: ebite,
    updown: eupdown,

    paint: epaint,
    stripe_colors: mrcolor4[],//TODO: color_option
    chequered_colors: mrcolor4[],//TODO: color_option
    mixed_colors: mrcolor4[],//TODO: color_option
    dracular_colors: mrcolor4[],//TODO: color_option
    splint_color: mrcolor4,
    splint_lingual_bar: boolean,
    splint_ball_clasp: eball_clasp,
    splint_material: ematerial,
    saturationFactor: number  // Add this line
}

export function is_3dprint(material: ematerial){
    return material === ematerial.blue3d || material === ematerial.clear3d  ;
}

export const default_model_paint_state: model_paint_state = is_dev() ? {
    paint: epaint.stripe,
    model: emodel.sportsguard,
    sportsguard_dracular: false,      
    splint_type: esplint.splint,
    // splint_material: ematerial.clear3d,
    splint_material: ematerial.thermo,
    splint_color: color_options.clear.mrc4,
    updown: eupdown.upper,
    stripe_colors: [color_options.red.mrc4, color_options.green.mrc4, color_options.blue.mrc4],
    chequered_colors: [color_options.white.mrc4, color_options.black.mrc4],
    mixed_colors: [color_options.red.mrc4, color_options.green.mrc4, color_options.blue.mrc4],
    dracular_colors: [color_options.red.mrc4, color_options.green.mrc4, color_options.blue.mrc4],
    cover: ecover.none,
    thickness: ethickness.thin,
    bite: ebite.none,
    splint_lingual_bar: false,
    splint_ball_clasp: eball_clasp.none,
    saturationFactor: 0.70  // Add this line
} : {
    paint: epaint.stripe,
    model: emodel.sportsguard,
    sportsguard_dracular: false,      
    splint_type: esplint.splint,
    splint_material: ematerial.thermo,
    splint_color: color_options.clear.mrc4,
    updown: eupdown.upper,
    stripe_colors: [color_options.red.mrc4, color_options.green.mrc4, color_options.blue.mrc4],
    chequered_colors: [color_options.white.mrc4, color_options.black.mrc4],
    mixed_colors: [color_options.red.mrc4, color_options.green.mrc4, color_options.blue.mrc4],
    dracular_colors: [color_options.red.mrc4, color_options.green.mrc4, color_options.blue.mrc4],
    cover: ecover.none,
    thickness: ethickness.thin,
    bite: ebite.none,
    splint_lingual_bar: false,
    splint_ball_clasp: eball_clasp.none,
    saturationFactor: 0.70  // Add this line
} 


export enum edecal{
    none,
    sticker,
    lettering,
    file,
    edit
}

export type decal_edit_state = {
    decal: edecal,
    sticker_category: string,
    sticker_name: string,
    lettering_text: string,
    lettering_color: mrcolor4,
    file_data: string,    
    decal_info?:decal
}

export const sticker_path = (category: string, name: string) => `./stickers/${category}/${name}`;
export const sticker_names = (category: string) => (stickers as Record<string, string[]>)[category];
const category = Object.keys(stickers)[0];
const name = sticker_names(category)[0];

export const default_decal_edit_state: decal_edit_state = {
    decal: edecal.none,
    sticker_category: category,
    sticker_name: name,
    lettering_text: "lettering",
    lettering_color: lettering_color_options.black.mrc4,
    file_data: ""
    //decalInfo? : decal
}

export type decal_manage_state = {    
    decal?: decal,
}

export const default_decal_manage_state: decal_manage_state = {    
}

export const sticker_size = 10;

export const TRANSPARENT_ALPHA_INDEX = 100;
export const DECAL_ALPHA_INDEX = 200;
export const DECAL_PREVIEW_ALPHA_INDEX = 300;




console.log();
