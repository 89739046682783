import { ArcRotateCamera, BaseTexture, Color4, DynamicTexture, Matrix, Mesh, Observer, PointerEventTypes, Quaternion, Ray, Scene, SceneLoader, Texture, Vector2, Vector3 } from "@babylonjs/core";
import { AdvancedDynamicTexture, Control, Rectangle, Vector2WithInfo } from "@babylonjs/gui";
import { attachment, attachment_templates, double_splint, dracular, model, splint, sportsguard } from "./models";
import { compare_mrcolor4, decal, decal_edit_state, decal_manage_state, ecover, edecal, ematerial, emodel, epaint, esplint, eupdown, is_3dprint, lerp, model_paint_state, mrcolor4, sticker_path } from "./state";

const root_name = 'context_root';

const context = {
    // scene: null as any as Scene,
    root: null as any as Mesh,
    model: null as any as model,
    arc: null as any as ArcRotateCamera,

    decals: [] as decal[],       

    model_paint_state_quqeue: [] as model_paint_state[],
    decal_edit_state_queue: [] as decal_edit_state[],
    decal_manage_state_queue: [] as decal_manage_state[],
    contact_edit: null as any as Mesh,
    contact_manage: null as any as Mesh,
    notify_decal_updated: null as any as (continue_edit : boolean) => void,
    notify_pick: null as any as (/*PI : PointerInfo*/) => void,

    attachments: null as any as attachment_templates,
    decal_cache: [] as BaseTexture[],

    dracular_teeth: null as any as HTMLImageElement,
    dracular_topline: null as any as HTMLImageElement,

    get scene(){
        return this.root && this.root.getScene();
    },

    get lettering_texture(){
        return (this.scene.textures.find(t => t.name === 'text_canvas') as DynamicTexture)!;
    },

    get ui_root(){
        let UI = this.scene.textures.find(t => t.name === 'UI') as AdvancedDynamicTexture;
        return UI.getChildren()[0];
    },



    load_scene: async function<T>(con: (new(mesh: Mesh) => T) & ({path: string})){        
        let {path} = con;
        let __root__ = this.root.getChildMeshes().find(n => n.name === path);
        
        console.log('load scene', path);
        // 메쉬에 재질 적용
        

        if(!__root__){
            let s = await SceneLoader.AppendAsync(
                "",         
                path,
                this.scene,
                (e) => console.debug(`${path} progress`, e),        
            );
        
            // const plasticMaterial = this.create_plastic_material(s);

            // if (path == "models/splint-upper-2mm.glb") {
            //     s.meshes.forEach(function(mesh) {
            //         if (mesh.subMeshes && mesh.subMeshes.length > 1) {
            //           // 멀티재질 생성
            //             var multiMaterial = new MultiMaterial("multiMaterial", s);
            //             var subMaterials = [];
    
            //           // 각 서브메시에 재질 적용
            //             for (var i = 0; i < mesh.subMeshes.length; i++) {
            //                 subMaterials.push(plasticMaterial);
            //             }
            //             multiMaterial.subMaterials = subMaterials;
            //             mesh.material = multiMaterial;
            //         } else {
            //           // 단일 재질 적용
            //             mesh.material = plasticMaterial;
            //         }
            //         // mesh.alphaIndex = 1; // 필요에 따라 렌더링 순서 조절
            //     });
            // }
                    

            __root__ = s.meshes.find(n => n.name === '__root__')!;
            __root__.name = path;


            __root__.parent = this.root;
        }
        
        return new con(__root__ as Mesh);
    },
    
    digest_state: async function(){
        const compare_colors = (x: mrcolor4[], y: mrcolor4[]) => {
            if(x.length === y.length){
                let i = 0;
                for(; i < x.length; ++i){
                    let xc = x[i];
                    let yc = y[i];
                    if(!compare_mrcolor4(xc, yc)){
                        break;
                    }
                }
                if(i === x.length){
                    return true;
                }
            }

            return false;            
        };

        let last: model_paint_state = null as any as model_paint_state;
        while(true){
            // check state pushed
            if(this.model_paint_state_quqeue.length){                
                // model first
                let tail = this.model_paint_state_quqeue[this.model_paint_state_quqeue.length - 1];
                let {model, thickness, bite, splint_type, sportsguard_dracular, updown} = tail;
                let load_model = !last 
                    || model !== last.model
                    || last.thickness !== thickness
                    || last.bite !== bite
                    || last.sportsguard_dracular !== sportsguard_dracular
                    || last.splint_type !== splint_type
                    || last.updown !== updown;

                if(load_model){
                    // load model
                    if(this.model){
                        this.model.dispose();
                        this.model = null as any as model;
                    }

                    if(model === emodel.sportsguard){
                        if(!sportsguard_dracular){
                            let instant_class = sportsguard.create_instant_class(thickness, bite);
                            this.model = await this.load_scene(instant_class);
                        }
                        else{
                            this.model = await this.load_scene(dracular);
                        }
                        
                        await wait_until(() => this.model.decalmap.isReady());
                    }                         
                    else if(model === emodel.splint){
                        if(!this.attachments){
                            const loadbc = (dir: string) => this.load_scene(attachment.create_instant_class(`ball-clasp-${dir}`));
    
                            this.attachments = {
                                lingual_bar: await this.load_scene(attachment.create_instant_class('lingual-bar')),
                                ball_clasp: {
                                    lrb: await loadbc('lrb'),
                                    lrf: await loadbc('lrf'),
                                    rlb: await loadbc('rlb'),
                                    rlf: await loadbc('rlf'),
                                }
                            }
                        }

                        if(splint_type === esplint.splint && updown === eupdown.both){
                            // merge
                            const upper_instant_class = await splint.create_instant_class({...tail, updown: eupdown.upper}, this.attachments);
                            const upper_model = await this.load_scene(upper_instant_class);
                            await wait_until(() => upper_model.decalmap.isReady());

                            const lower_instant_class = await splint.create_instant_class({...tail, updown: eupdown.lower}, this.attachments);
                            const lower_model = await this.load_scene(lower_instant_class);
                            await wait_until(() => lower_model.decalmap.isReady());

                            this.model = new double_splint(upper_model, lower_model);
                            await wait_until(() => this.model.decalmap.isReady());
                        }
                        else{
                            let instant_class = await splint.create_instant_class(tail, this.attachments);
                            this.model = await this.load_scene(instant_class);
                            await wait_until(() => this.model.decalmap.isReady());
                        }                        
                    }
                }

                // paint
                let {paint} = tail;
                let {stripe_colors, chequered_colors, mixed_colors, dracular_colors, splint_color, splint_material} = tail;   

                let paint_model = !last
                    || load_model
                    || (paint !== last.paint)
                    || (splint_material !== last.splint_material)
                    || !compare_colors(stripe_colors, last.stripe_colors)
                    || !compare_colors(chequered_colors, last.chequered_colors)
                    || !compare_colors(mixed_colors, last.mixed_colors)
                    || !compare_colors(dracular_colors, last.dracular_colors)
                    || !compare_colors([splint_color], [last.splint_color]);

                if(paint_model){
                    if(model === emodel.sportsguard){
                        if(!sportsguard_dracular){
                            switch(paint){
                                case epaint.stripe: this.model.stripe(stripe_colors); break;
                                case epaint.chequered: this.model.chequered(chequered_colors[0], chequered_colors[1]); break;                                
                                case epaint.mixed: this.model.mixed(mixed_colors); break;
                            }
                        }
                        else{
                            this.model.dracular(dracular_colors[0], dracular_colors[1], dracular_colors[2]);
                        }                        
                    }                    
                    else if(model === emodel.splint){
                        // adjust color for splint
                        let adjusted = splint_color;

                        // ensure transparent
                        if([ematerial.blue3d, ematerial.clear3d].includes(splint_material)){
                            adjusted = {
                                m: adjusted.m,
                                r: adjusted.r,
                                c: new Color4(
                                    adjusted.c.r,
                                    adjusted.c.g,
                                    adjusted.c.b,
                                    0,
                                ),
                            }
                        }                        
                        
                        this.model.splint({
                            c: adjusted.c,
                            m: adjusted.m,
                            r: 0,
                        });
                    }
                }   
                
                // cover
                let {cover} = tail;
                let update_cover = load_model
                    || (model === emodel.sportsguard && cover !== last.cover);

                if(update_cover){
                    if(model === emodel.sportsguard && !sportsguard_dracular){                    
                        this.model.short_covered = cover === ecover.short;
                        this.model.long_covered = cover === ecover.long;
                    }
                }

                // attachments
                let{splint_lingual_bar, splint_ball_clasp} = tail;
                let update_attachment = load_model
                    || splint_lingual_bar !== last.splint_lingual_bar
                    || splint_ball_clasp !== last.splint_ball_clasp;

                if(update_attachment){
                    if(model === emodel.splint){
                        this.model.attachment(splint_lingual_bar, splint_ball_clasp);
                    }
                }

                // clear decals if model changed
                let decal_updated = false;
                if(last && model !== last.model){
                    this.decals = [];
                    decal_updated = true;
                    this.notify_decal_updated(false);
                }                

                // refresh decals                
                if(decal_updated || update_cover){
                    this.model.render_decals(this.decals);
                }

                const decal_invisible = tail.sportsguard_dracular 
                    || (tail.model === emodel.splint && is_3dprint(tail.splint_material));
                for(const d of this.decals){
                    const mesh = d.mesh;
                    if(mesh){
                        mesh.isVisible = !decal_invisible
                    }
                }
                
                // cache
                last = {
                    model,
                    sportsguard_dracular,
                    splint_type,
                    paint,
                    thickness,
                    bite,
                    updown,
                    stripe_colors,
                    chequered_colors,
                    dracular_colors,
                    mixed_colors,
                    splint_color,
                    cover,
                    splint_lingual_bar,
                    splint_ball_clasp,
                    splint_material,
                    saturationFactor: 0.85  // Add this line
                };

                this.model_paint_state_quqeue = [];
            }

            // throttling
            await new Promise(res => setTimeout(res, 100));
        }
    },

    start: async function(scene: Scene){        
        console.log('context started');

        // dispose last root
        let root_node = scene.rootNodes.find(rn => rn.name === root_name);
        if(root_node){
            root_node.dispose();
        }

        this.root = new Mesh(root_name, scene);
        (window as any)['context'] = this;        

        // wait until scene components provided
        await wait_until(() => 
            !!this.scene.textures.find(t => t.name === 'UI') 
            && !!this.contact_edit 
            && !!this.contact_manage
            && !!this.arc
        );

        let background = this.ui_root.getChildByName('background')!;
        background.onPointerClickObservable.add(PI => {
            if(this.notify_pick){
                this.notify_pick();
            }
        });

        this.digest_state();
        this.edit_decal();
        this.manage_decal();
    },

    

    async get_decal_texture(state: decal_edit_state){
        console.assert(state.decal !== edecal.none);
        const {decal_cache} = this;
        let tex: BaseTexture | null = null;
        switch(state.decal){
            case edecal.sticker: {
                const path = sticker_path(state.sticker_category, state.sticker_name)
                const key = `sticker:${path}`;
                tex = decal_cache.find(t => t.name === key)!;
                if(!tex){
                    let new_tex = new Texture(path);
                    await new Promise(res => new_tex.onLoadObservable.add(res));
                    new_tex.name = key;
                    decal_cache.push(new_tex);
                    tex = new_tex;
                }
            }break;
            case edecal.lettering: {
                const {lettering_texture} = this;
                let cv = model.scratch_canvas;
                let ctx = cv.getContext('2d')!;
                let c = new Vector2(cv.width / 2, cv.height / 2);

                // TODO: measure tet and resize
                ctx.clearRect(0, 0, cv.width, cv.height);
                ctx.font = '900 50px inter';        
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';

                const font_color = state.lettering_color.c;

                ctx.lineWidth = 10;
                ctx.strokeStyle = 'white';        
                ctx.strokeText(state.lettering_text, c.x, c.y);

                if(font_color.r + font_color.g + font_color.b > 2.5){
                    ctx.lineWidth = 5;
                    ctx.strokeStyle = 'black';        
                    ctx.strokeText(state.lettering_text, c.x, c.y);
                }
                
                ctx.fillStyle = font_color.toHexString();
                ctx.fillText(state.lettering_text, c.x, c.y);

                lettering_texture.clear();
                lettering_texture.getContext().drawImage(
                    cv, 
                    0, 
                    0, 
                    lettering_texture.getSize().width,
                    lettering_texture.getSize().height
                );
                
                lettering_texture.update();
                tex = lettering_texture;
            }break;
            case edecal.file: {
                const {file_data} = state;
                if(file_data && file_data.length){
                    let key = `user_image:${file_data}`;
                    tex = decal_cache.find(t => t.name === key)!;
                    if(!tex){
                        let new_tex = new Texture(file_data);                        
                        await new Promise(res => new_tex.onLoadObservable.add(res));
                        new_tex.name = key;
                        decal_cache.push(new_tex);
                        tex = new_tex;
                    }
                }
            }break;
        }
        console.assert(tex);
        return tex!;
    },

    reset_contact(contact: Mesh, decal: decal){
        contact.position = decal.position;
        contact.rotationQuaternion = Quaternion.FromLookDirectionRH(
            decal.normal,
            Vector3.Up(),
        ).multiply(Quaternion.FromEulerAngles(0, 0, -decal.angle_rad));
        contact.scaling = new Vector3(decal.scale, decal.scale, 0.1);
    },

    async manage_decal(){
        let {arc, contact_manage} = this;

        let rect = this.ui_root.getChildByName('sticker-manage')!;

        const interactable = (name: string) => {
            return {
                control: (rect as Rectangle).getChildByName(name)!,
                value: false,
                ob: null as any as Observer<Vector2WithInfo>,
                get check(){
                    return () => this.value = true;
                }
            }
        };
        
        let cancel = interactable('cancel')!;
        let interactables = [cancel];            

        rect.linkWithMesh(contact_manage.getChildTransformNodes().find(n => n.name === 'lt')!);        

        while(true){
            
            set_visible(false, contact_manage, rect);

            await wait_until(() => 
                this.decal_manage_state_queue.length > 0
                && !!this.decal_manage_state_queue[this.decal_manage_state_queue.length - 1].decal
            );      

            let tail = this.decal_manage_state_queue[this.decal_manage_state_queue.length - 1];
            this.decal_manage_state_queue = [];

            const move_camera = async () => {
                //
                return;
                //

                arc.detachControl();
                const {decal} = tail;                
                let direction = decal!.position.subtract(arc.target).normalize();
                if(direction.dot(decal!.normal) < 0){
                    direction = direction.scale(-1);
                }
                let distance = arc.radius;                  
                const last = {
                    alpha: arc.alpha,
                    beta: arc.beta,
                    radius: arc.radius,
                };
                arc.position = arc.target.add(direction.multiplyByFloats(distance, distance, distance));;
                arc.rebuildAnglesAndRadius();
                const target = {
                    alpha: arc.alpha,
                    beta: arc.beta,
                    radius: arc.radius,
                };

                const fromMS = Date.now()
                const durationMS = 200;
                
                while(Date.now() < fromMS + durationMS){                    
                    const progress01 = (Date.now() - fromMS) / durationMS;                    
                    arc.alpha = lerp(last.alpha, target.alpha, progress01);
                    arc.beta = lerp(last.beta, target.beta, progress01);
                    arc.radius = lerp(last.radius, target.radius, progress01);
                    await new Promise(res => this.scene.getEngine().onEndFrameObservable.addOnce(res));
                }
                arc.alpha = target.alpha;
                arc.beta = target.beta;
                arc.radius = target.radius;
                arc.attachControl(true);
            }
            
            while(tail.decal && this.decals.includes(tail.decal)){
                set_visible(true, contact_manage, rect);
                await move_camera();
                this.reset_contact(contact_manage, tail.decal!);

                for(const i of interactables){
                    i.value = false;
                }

                cancel.ob = cancel.control.onPointerClickObservable.add(cancel.check);

                await wait_until(() =>        
                    interactables.some(i => i.value)
                    || this.decal_manage_state_queue.length > 0
                );

                for(const i of interactables){
                    i.ob.remove();
                }

                if(cancel.value){                    
                    let {decal: tail_decal} = tail;                    
                    const i = this.decals.findIndex(d => d === tail_decal);                    
                    if(i >= 0){
                        this.decals.splice(i, 1);
                        this.model.render_decals(this.decals);
                        this.notify_decal_updated(false);
                        tail.decal = undefined;
                    }
                }
                else if(this.decal_manage_state_queue.length){
                    const last_decal = tail.decal;
                    tail = this.decal_manage_state_queue[this.decal_manage_state_queue.length - 1];
                    this.decal_manage_state_queue = [];

                    if(tail.decal && tail.decal !== last_decal){
                        await move_camera();
                    }
                }
            }
        }
    },
        
    async edit_decal() {        
        let {scene, arc, contact_edit} = this;

        await wait_until(() => !!scene.textures.find(t => t.name === 'UI'));
        let rect = this.ui_root.getChildByName('sticker-edit')!;

        const interactable = (name: string) => {
            return {
                control: (rect as Rectangle).getChildByName(name)!,
                value: false,
                ob: null as any as Observer<Vector2WithInfo>,
                get check(){
                    return () => this.value = true;
                }
            }
        };
        
        let rotation = interactable('rotation')!;        
        let scale = interactable('scale')!;
        let mirror = interactable('mirror')!;
        let check = interactable('check')!;
        let add = interactable('add')!;
        let cancel = interactable('cancel')!;        
        let interactables = [rotation, scale, mirror, check, add, cancel];
        
        rect.linkWithMesh(contact_edit.getChildTransformNodes().find(n => n.name === 'lt')!);

        while(true){            
            set_visible(false, contact_edit, rect);

            // {
            // warm-up decalmap. 
            // TODO: find out reason. sharing decalmap.Texture can be reason...
            await wait_until(() => !!this.model);
            this.model.decalmap.renderTexture(
                scene.textures[0], 
                Vector3.Zero(), 
                Vector3.One(), 
                Vector3.One(), 
                0
            );
            // }
            
            
            await wait_until(() => 
                this.decal_edit_state_queue.length > 0
                && this.decal_edit_state_queue[this.decal_edit_state_queue.length - 1].decal !== edecal.none
            );        

            let tail = this.decal_edit_state_queue[this.decal_edit_state_queue.length - 1];
            this.decal_edit_state_queue = [];
            
            // sportsguard can be changed while awaiting
            let {model} = this;
            
            console.log('start edit sticker');

            var swatch_decal : any = undefined ;
        
            if (tail.decal === edecal.edit) {

                tail.decal = tail.decal_info!.decal;

                let {decal_info: tail_decal} = tail;                    
                const i = this.decals.findIndex(d => d === tail_decal);                    
                if(i >= 0){
                    this.decals.splice(i, 1);
                    this.model.render_decals(this.decals);
                    //this.notify_decal_updated(false);
                    //tail.decal = undefined;
                }

                swatch_decal = tail.decal_info;

            } else {
                // swatch decal
                let ray = new Ray(
                    new Vector3(0, -3, 100,), 
                    new Vector3(0, 0, -1),
                );

                let pick_info = scene.pickWithRay(ray, (m => m === model.decal_receiver))!;
                if(!pick_info.hit){
                    ray = new Ray(
                        new Vector3(0, 3, 100,), 
                        new Vector3(0, 0, -1),
                    );                
                    pick_info = scene.pickWithRay(ray, (m => m === model.decal_receiver))!;
                }
                if(!pick_info.hit){
                    ray = new Ray(
                        new Vector3(100, 3, 0,), 
                        new Vector3(-1, 0, 0),
                    );                                
                    pick_info = scene.pickWithRay(ray, (m => m === model.decal_receiver))!;
                }
                
                swatch_decal = {
                    normal: pick_info.getNormal(true),
                    position: pick_info.pickedPoint!,
                    tex: await this.get_decal_texture(tail),
                    angle_rad: 0,
                    scale: 1,
                    hmirror: false,
                    ray,
                    decal: tail.decal,
                    lettering_text: '',
                    lettering_color: {c: new Color4(0, 0, 0, 1), m: 0, r: 0},
                } as decal;
                
            }


            this.reset_contact(contact_edit, swatch_decal);

            model.preview_decal(swatch_decal);
            
            while(tail.decal !== edecal.none){                
                set_visible(true, contact_edit, rect, ...interactables.map(i => i.control));

                let down_on_contact = false;
                for(const i of interactables){
                    i.value = false;
                }
                
                let wait_start_move = scene.onPointerObservable.add((e, s) => {
                    if(e.type === PointerEventTypes.POINTERDOWN){
                        var pickResult = scene.pick(scene.pointerX, scene.pointerY, (m) => m === contact_edit);
                        if(pickResult.hit){
                            down_on_contact = true;
                        }
                    }
                });

                rotation.ob = rotation.control.onPointerDownObservable.add(rotation.check);
                scale.ob = scale.control.onPointerDownObservable.add(scale.check);
                mirror.ob = mirror.control.onPointerClickObservable.add(mirror.check);
                check.ob = check.control.onPointerClickObservable.add(check.check);
                add.ob = add.control.onPointerClickObservable.add(add.check);
                cancel.ob = cancel.control.onPointerClickObservable.add(cancel.check);
                
                await wait_until(() =>                 
                    down_on_contact 
                    || interactables.some(i => i.value)
                    || this.decal_edit_state_queue.length > 0
                );

                for(const i of interactables){
                    i.ob.remove();
                }
                
                wait_start_move.remove();

                if(down_on_contact || rotation.value || scale.value){
                    arc.detachControl();
                    
                    if(rotation.value){
                        show_exclusive(rotation.control);
                    }
                    else if(scale.value){
                        show_exclusive(scale.control);
                    }
                    else if(down_on_contact){
                        rect.isVisible = false;
                    }

                    let canvas = scene.getEngine().getRenderingCanvas()!;                    
                    let up_on_somewhere = false;
                    let contact_xy = Vector3.Project(
                        contact_edit.position,
                        Matrix.Identity(),
                        scene.getTransformMatrix(),
                        arc.viewport,
                    );
                    contact_xy.x *= canvas.clientWidth;
                    contact_xy.y *= canvas.clientHeight;

                    const last_linkoffset = get_linkoffset(rect);

                    // linkoffsets are numbers
                    // angle from y axis
                    const last_UI_angle = Math.atan2(last_linkoffset.y, last_linkoffset.x);
                    let UI_offset_length = last_linkoffset.length();
                    let pointer_xy = new Vector2(
                        scene.pointerX,
                        scene.pointerY,
                    );
                    // console.log('from', last_linkoffset.x, last_linkoffset.y);
                    const last_touch_offset = new Vector2(pointer_xy.x - contact_xy.x, pointer_xy.y - contact_xy.y);
                    const last_touch_aspect_ratio = Math.abs(last_touch_offset.x / last_touch_offset.y);

                    const last_scale = swatch_decal.scale;
                    const last_angle_rad = swatch_decal.angle_rad
                    
                    let wait_end_move = scene.onPointerObservable.add((e, s) => {
                        let touch_offset = new Vector2(scene.pointerX - contact_xy.x, scene.pointerY - contact_xy.y);
                        if(e.type === PointerEventTypes.POINTERMOVE){
                            if(rotation.value){
                                let last_touch_angle = Math.atan2(last_touch_offset.y, last_touch_offset.x);
                                
                                let angle = Math.atan2(
                                    touch_offset.y, 
                                    touch_offset.x,
                                );
                                
                                let angle_diff = angle - last_touch_angle;
                                let UI_angle = last_UI_angle + angle_diff;
                                
                                swatch_decal.angle_rad = last_angle_rad + angle_diff;
                            }
                            else if(scale.value){
                                let touch_aspect_ratio = Math.abs(
                                    touch_offset.x / touch_offset.y
                                );

                                let modified_touch_offset = touch_aspect_ratio  > last_touch_aspect_ratio
                                    ? new Vector2(touch_offset.x, touch_offset.x / last_touch_aspect_ratio)
                                    : new Vector2(touch_offset.y * last_touch_aspect_ratio, touch_offset.y);

                                let offset_scale = new Vector2(
                                    Math.abs(modified_touch_offset.x / last_touch_offset.x),
                                    Math.abs(modified_touch_offset.y / last_touch_offset.y),
                                );
                                
                                console.assert(Math.abs(offset_scale.x - offset_scale.y) < 0.1);
                                
                                // no need to scale offset. anchor is translated below
                                // set_linkoffset(rect, last_linkoffset.multiply(offset_scale));

                                swatch_decal.scale = last_scale * offset_scale.x;
                            }     
                            else if(down_on_contact){                                                                
                                var pickResult = model.pick();
                                if(pickResult.hit){                                    
                                    const normal = pickResult.getNormal(true);
                                    const position = pickResult.pickedPoint;
                                    swatch_decal.position = position!;
                                    swatch_decal.normal = normal!;
                                    swatch_decal.ray = pickResult.ray!;
                                }
                            }                       

                            this.reset_contact(contact_edit, swatch_decal);
                            model.preview_decal(swatch_decal);
                        }
                        else if(e.type === PointerEventTypes.POINTERUP){
                            up_on_somewhere = true;
                        }
                    });
                    
                    await wait_until(() => up_on_somewhere);

                    wait_end_move.remove();
                    set_linkoffset(rect, last_linkoffset);
                    
                    arc.attachControl(true);
                }
                else if(mirror.value){
                    swatch_decal.hmirror = !swatch_decal.hmirror;
                    this.reset_contact(contact_edit, swatch_decal);
                    model.preview_decal(swatch_decal);
                }
                else if(check.value || add.value){
                    const {lettering_texture} = this;
                    if(swatch_decal.tex === lettering_texture){
                        let png = lettering_texture.getContext().canvas.toDataURL('image/png');
                        let new_tex = new Texture(png);                        
                        await new Promise(res => new_tex.onLoadObservable.add(res));
                        new_tex.name = `lettering:${tail.lettering_text}:${tail.lettering_color.c.toHexString()}`;
                        
                        swatch_decal.lettering_color = tail.lettering_color; 
                        swatch_decal.lettering_text = tail.lettering_text;
                        this.decals.push({...swatch_decal, tex: new_tex});

                    }
                    else{
                        this.decals.push({...swatch_decal});
                    }

                    model.render_decals(this.decals);

                    if(check.value){
                        this.notify_decal_updated(false);
                    }
                    else if(add.value){
                        this.notify_decal_updated(true);

                        // offset next
                        swatch_decal.ray = new Ray(
                            swatch_decal.ray.origin.add(new Vector3(0.1, 0.1, 0.1)),
                            swatch_decal.ray.direction
                        );
                        
                        var pickResult = model.pick();
                        if(pickResult.hit){
                            const normal = pickResult.getNormal(true);
                            const position = pickResult.pickedPoint;
                            swatch_decal.position = position!;
                            swatch_decal.normal = normal!;
                            swatch_decal.ray = pickResult.ray!;
                        }

                        this.reset_contact(contact_edit, swatch_decal);
                        model.preview_decal(swatch_decal);
                    }
                }
                else if(cancel.value){
                    tail.decal = edecal.none;
                }
                else if(this.decal_edit_state_queue.length){
                    tail = this.decal_edit_state_queue[this.decal_edit_state_queue.length - 1];
                    this.decal_edit_state_queue = [];
                    if(tail.decal !== edecal.none){
                        const decal_tex = await this.get_decal_texture(tail);
                        if(swatch_decal.tex !== decal_tex
                            || decal_tex === this.lettering_texture){
                            swatch_decal.tex = decal_tex;

                            // TODO: update base size

                            model.preview_decal(swatch_decal);

                        }
                        
                    }
                }
            }

            this.model.preview_decal();

            console.log('end edit sticker');
        }
    },
};

// TODO: lightweight frame waiting
async function wait_until(condition: () => boolean){
    while(!condition()){
        await new Promise(res => setTimeout(res, 1));
    }
}

function get_linkoffset(control: Control){
    return new Vector2(
        control.linkOffsetXInPixels,
        control.linkOffsetYInPixels,
    );
}

function set_linkoffset(control: Control, v: Vector2){
    control.linkOffsetXInPixels = v.x;
    control.linkOffsetYInPixels = v.y;
}

function set_visible(visible: boolean, ...controls: {isVisible: boolean}[]){
    for(let c of controls){
        c.isVisible = visible;
    }
}

function show_exclusive(control: Control){
    let p = control.parent;
    if(p){
        for(let c of p.children){
            c.isVisible = control === c;
        }
    }
}

export default context;
