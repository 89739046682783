import React, { useState, useEffect } from 'react';
import context from '../context';
import { model_paint_state } from '../state';

interface Props {
    model_paint_state: model_paint_state;
    update_model_paint: (patch: Partial<model_paint_state>) => void;
}

export const SaturationControl: React.FC<Props> = ({ model_paint_state, update_model_paint }) => {
    const [isVisible, setIsVisible] = useState(false);

useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key.toLowerCase() === 't') {
            setIsVisible(prev => !prev);
        }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
        window.removeEventListener('keydown', handleKeyPress);
    };
}, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSaturation = parseFloat(e.target.value);
        // Update local state
        update_model_paint({ saturationFactor: newSaturation });
        
        // Update context for history
        if (context.model_paint_state_quqeue.length > 0) {
            const currentState = context.model_paint_state_quqeue[context.model_paint_state_quqeue.length - 1];
            const newState = {
                ...currentState,
                saturationFactor: newSaturation
            };
            context.model_paint_state_quqeue.push(newState);
            
            // Trigger repaint
            if (context.model) {
                context.model.splint(newState.splint_color);
            }
        }
    };

    const currentSaturation = model_paint_state.saturationFactor ?? 0.70;

    if (!isVisible) return null;

    return (
        <div style={{
            position: 'fixed',
            right: '20px',
            top: '20%',
            transform: 'translateY(-50%)',
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            width: '200px',
            zIndex: 1000
        }}>
            <div style={{ 
                marginBottom: '8px', 
                fontSize: '14px',
                color: '#333',
                fontWeight: 500
            }}>
                Color Saturation: {Math.round(currentSaturation * 100)}%
            </div>
            <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={currentSaturation}
                defaultValue="0.70"
                onChange={handleChange}
                style={{ 
                    width: '100%',
                    accentColor: '#2196f3'
                }}
            />
        </div>
    );
};
