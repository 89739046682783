import { Mesh } from "@babylonjs/core";
import {parse, unparse} from 'papaparse';

const __default__ = {
    "lingual_bar": {
        "px": 0,
        "py": 0,
        "pz": -1.5,
        "rx": 0,
        "ry": 0,
        "rz": 0,
        "sx": 1,
        "sy": 1,
        "sz": 1
    },
    "ball_clasp_left_45": {
        "px": 17.36458969116211,
        "py": -0.5416408777236938,
        "pz": 2.9346165657043457,
        "rx": -2.4406836135471014e-8,
        "ry": -0.4201043606967524,
        "rz": 0.4429920613093699,
        "sx": 1.0000001300484334,
        "sy": 0.9999998945566854,
        "sz": 1.0000001048170712
    },
    "ball_clasp_left_56": {
        "px": 19.508460998535156,
        "py": -1.2762384414672852,
        "pz": -5.476463317871094,
        "rx": 0.020474991793491642,
        "ry": -0.4937329862966347,
        "rz": 0.37810027962818815,
        "sx": 0.9999996002031648,
        "sy": 1.0000001400630762,
        "sz": 0.9999999074673529
    },
    "ball_clasp_left_67": {
        "px": 22.948467254638672,
        "py": -0.6807900667190552,
        "pz": -15.473633766174316,
        "rx": -0.08113761943950151,
        "ry": -0.3616879480681639,
        "rz": 0.3470917249118288,
        "sx": 0.9999997775037529,
        "sy": 1.0000000604796981,
        "sz": 0.9999998563109916
    },
    "ball_clasp_right_45": {
        "px": -19.30869483947754,
        "py": -1.2121319770812988,
        "pz": 2.5795745849609375,
        "rx": -0.04708423048402174,
        "ry": 0.29942019400892195,
        "rz": -0.608524709181966,
        "sx": 0.999999897461097,
        "sy": 1.0000000367819608,
        "sz": 1.000000033892048
    },
    "ball_clasp_right_56": {
        "px": -20.69422721862793,
        "py": -0.9390304088592529,
        "pz": -5.2892985343933105,
        "rx": 0.04884744519947173,
        "ry": 0.3499158023632913,
        "rz": -0.43147142396256233,
        "sx": 0.9999997932010206,
        "sy": 1.0000000408070557,
        "sz": 1.0000001814349602
    },
    "ball_clasp_right_67": {
        "px": -24.880338668823242,
        "py": -0.9202923774719238,
        "pz": -15.634757041931152,
        "rx": -0.04824490513188651,
        "ry": 0.31229953328529564,
        "rz": -0.48736499899347996,
        "sx": 1.0000000824080801,
        "sy": 0.999999968226358,
        "sz": 1.0000001521342863
    }
};

export type offset = {
    px: number,
    py: number,
    pz: number,
    rx: number,
    ry: number,
    rz: number,
    sx: number,
    sy: number,
    sz: number,
}

export type attachments<T> = {
    lingual_bar: T,
    ball_clasp_left_45: T,
    ball_clasp_left_56: T,
    ball_clasp_left_67: T,
    ball_clasp_right_45: T,
    ball_clasp_right_56: T,
    ball_clasp_right_67: T,
}

let offset_sheet = null as any as Record<string, string>[];

export async function get_attachment_offset(prefix: string): Promise<attachments<offset>>{
    let result: attachments<offset> = __default__;

    try{        
        if(!offset_sheet){
            const csv = await fetch(`./attachment-offsets.csv`).then(r => r.text());
            offset_sheet = parse<Record<string, string>>(csv, {header: true}).data;
            console.log(offset_sheet);
        }

        const row = offset_sheet.find(r => r['model'] === prefix);
        result = JSON.parse(JSON.stringify(result));
        for(const k in row){
            const tokens = k.split(':');
            if(tokens.length === 2){
                (result as any)[tokens[0]][tokens[1]] = +row[k];
            }
        }
    }
    catch(e){
        console.error(e);
    }

    return result;
}

export function export_attachment_offset(prefix: string, meshes: attachments<Mesh> ){
    const result = {} as attachments<offset>;
    for(let k in meshes){
        const typed = k as keyof attachments<Mesh>;
        const mesh = meshes[typed];
        result[typed] = {
            px: mesh.position.x,
            py: mesh.position.y,
            pz: mesh.position.z,
            rx: mesh.rotation.x,
            ry: mesh.rotation.y,
            rz: mesh.rotation.z,
            sx: mesh.scaling.x,
            sy: mesh.scaling.y,
            sz: mesh.scaling.z,
        };
    }
    
    console.log(result);

    const row = offset_sheet.find(r => r['model'] === prefix)!;

    for(const k0 in result){
        for(const k1 in (result as any)[k0]){
            row[`${k0}:${k1}`] = (result as any)[k0][k1];
        }
    }

    const csv = unparse(offset_sheet, {header: true});
    console.log(csv)
    navigator.clipboard.writeText(csv);    
}