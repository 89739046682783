import { ArcRotateCamera, Engine as BabylonEngine, Scene as BabylonjsScene, BackgroundMaterial, Color3, Color4, CubeTexture, DynamicTexture, FresnelParameters, ILoadingScreen, Mesh, Nullable, Observer, PBRMaterial, Texture, Vector3 } from "@babylonjs/core";
import { Rectangle } from "@babylonjs/gui";
import { ReactNode, RefObject, useRef, useState } from "react";
import { Engine, Scene, useEngine } from "react-babylonjs";
import context from "./context";
import { sticker_size } from "./state";

const env_url_to_skytexture_reflectiontexture = {
    
    'skyboxes/skybox': null,
    'skyboxes/Studio_Softbox_2Umbrellas_cube_specular.env': null,
    'skyboxes/environment.env': null,    
    // 'skyboxes/hamburg': null,
    'skyboxes/hamburg_hbf.env': null,
    'skyboxes/country.env': null,    
} as {[url: string]: Nullable<[CubeTexture, CubeTexture]>};

type props = {
    loading_screen: ILoadingScreen,
    env_index: number,
}

function UseEngine(props: props){
    const engine = useEngine();    
    engine!.loadingScreen = props.loading_screen;
    return null;
}

function ViewportConfigurabelCamera(props: {ssize: ssize}){
    const arc = useRef<ArcRotateCamera>(null);    
    context.arc = arc.current!;

    if(arc.current){
        const cam = arc.current;
        const {w, h} = props.ssize;
        if(w > h){
            const right = 200;
            const top = 50;    
            const extent_w_01 = right / w;
            const extent_h_01 = top / h;
            cam.viewport.x = -extent_w_01;
            cam.viewport.width = 1 + extent_w_01;
            cam.viewport.y = -extent_h_01;
            cam.viewport.height = 1 + extent_h_01;
            cam.fovMode = ArcRotateCamera.FOVMODE_VERTICAL_FIXED;
        }
        else{
            const bottom = 200;
            const extent_h_01 = bottom / h;
            cam.viewport.height = 1 + extent_h_01;
            cam.fovMode = ArcRotateCamera.FOVMODE_HORIZONTAL_FIXED;
        }
    }

    return (
        <arcRotateCamera                 
            name="arc" 
            target={new Vector3(0, 0, 0)}	
            fov={Math.PI / 8}						
            alpha={Math.PI * 0.25}                             
            beta={Math.PI * 0.33}
            upperBetaLimit={Math.PI * 1} 
            lowerBetaLimit={Math.PI * 0} 
            wheelPrecision={25}
            radius={200}                         						
            lowerRadiusLimit={100} 
            upperRadiusLimit={250} 
            minZ={10}                     
            maxZ={1100}        
            ref={arc}            
        />
    );
}

type ssize = {
    w: number,
    h: number,
    cw: number,
    ch: number,
}

function make_layout(count: number, ssize: ssize){
    // .icon-button witdh * 2. App.css    
    let multiplier = ssize.w / ssize.cw;
    const size = 50 * multiplier;
    const margin = 3;  
    const corner_radius = size / 2;  
    
    const wide = ssize.w > ssize.h;
    const long = size * count + margin * (count + 1);
    const short = size + margin * 2;
    const w = wide ? long : short;
    const h = wide ? short : long;
    return {
        size,
        margin,
        corner_radius,
        w,
        h,
        wide,
        offsets: Array(count).fill(0).map((n, i) => (-(wide ? w : h) / 2) + margin + (size + margin) * i + size / 2)
    };
}


function IconButton(props: {name: string, length: number, index: number, icon: string, ssize: ssize}){    
    const layout = make_layout(props.length, props.ssize);
    const normal_url = `icons/${props.icon}_n.png`;    
    const pressed_url = `icons/${props.icon}_p.png`;    
    const [pressed, set_pressed] = useState(false);
    const offset = layout.offsets[props.index];
    
    return (        
        <babylon-image            
            name={props.name}
            topInPixels={layout.wide ? 0 : offset}
            leftInPixels={layout.wide ? offset : 0}
            source={pressed ? pressed_url : normal_url}
            width={`${layout.size}px`}
            height={`${layout.size}px`}
            onPointerDownObservable={() => set_pressed(true)}
            onPointerUpObservable={() => set_pressed(false)}
            onPointerOutObservable={() => set_pressed(false)}
        />
    );
}

export function BabylonView(props: props){
    // console.log('BabylonView Rendered');
    const [scene, set_scene] = useState<BabylonjsScene>();
    const base_texture = useRef<DynamicTexture>(null);
    const mr_texture = useRef<DynamicTexture>(null);
    const contact_edit = useRef<Mesh>(null);
    const contact_manage = useRef<Mesh>(null);
    const sticker_edit = useRef<Rectangle>(null);
    const sticker_manage = useRef<Rectangle>(null);  
    const background_material = useRef<BackgroundMaterial>(null);  
    const [resize_ob, set_resize_ob] = useState<Observer<BabylonEngine>>();
    const [ssize, set_size] = useState({w:100, h:100, cw: 100, ch: 100});

    const default_bg = (name: string, length: number, children: ReactNode, ref: RefObject<Rectangle>) => {
        const layout = make_layout(length, ssize);
        return (
            <rectangle
                name={name}
                width={`${layout.w}px`}
                height={`${layout.h}px`}
                cornerRadius={(layout.size + layout.margin) / 2}
                thickness={0}
                background='#ffffff32'
                linkOffsetY={layout.wide ? -layout.h / 2 : 0}
                linkOffsetX={layout.wide ? layout.w / 2 : layout.w}    
                ref={ref}                    
            >
                {children}
            </rectangle>
        );
    }

    // cubetexture node(babylon-react) not re-rendered when rootURL changed. so we refresh it manually
    
    const env_urls = Object.keys(env_url_to_skytexture_reflectiontexture);
    const env_url = env_urls[props.env_index % env_urls.length];
    if(scene){
        if(!context.scene){
            context.start(scene);
        }

        const update_ssize = () => {
            const canvas = scene.getEngine().getRenderingCanvas()!;
            const current_size = {
                w: canvas.width,
                h: canvas.height,
                cw: canvas.clientWidth,
                ch: canvas.clientHeight,
            };
            if(current_size.w != ssize.w
                || current_size.h != ssize.h
                || current_size.cw != ssize.cw
                || current_size.ch != ssize.ch
            ){
                
                set_size(current_size);
            }
        };
        update_ssize();

        if(!resize_ob){
            set_resize_ob(scene.getEngine().onResizeObservable.add(update_ssize));
        }

        // TODO: query from scene.textures
        // console.log(env_url)
        let textures = env_url_to_skytexture_reflectiontexture[env_url];
        if(!textures){
            // if(env_url.indexOf('hbf') < 0){
                textures = [
                    new CubeTexture(env_url, scene),
                    new CubeTexture(env_url, scene),
                ];
            // }     
            // else{
            //     textures = [
            //         CubeTexture.CreateFromPrefilteredData(env_url, scene),
            //         CubeTexture.CreateFromPrefilteredData(env_url, scene),
            //     ];
            // }       
            textures[0].coordinatesMode = Texture.SKYBOX_MODE;
            env_url_to_skytexture_reflectiontexture[env_url] = textures;
        }
        
        scene.environmentTexture = textures[1]
        if(background_material.current){
            background_material.current.reflectionTexture = textures[0];
        }
    }

    context.contact_edit = contact_edit.current!;
    context.contact_manage = contact_manage.current!;
        
    return (
        <Engine 
			antialias={true} 
			adaptToDeviceRatio={true} 
			canvasId="scene-canvas"
			style={{
				position: 'absolute', 
				left: 0, 
				bottom: 0
            }}            
            
            // BABYLON BUG: not works
            // onResize={reset_camera_setting}
        >
            <UseEngine                
                loading_screen={props.loading_screen}
                env_index={props.env_index}
            />
            <Scene 
                onSceneMount={(SEA) => set_scene(SEA.scene)}        
                useRightHandedSystem={true}
            >
                <ViewportConfigurabelCamera
                    ssize={ssize}
                />
                <hemisphericLight 
					name='hemi' 
                    direction={new Vector3(0, -1, -1)} 
                    intensity={0.5}/>
                <directionalLight 
					name="shadow-light" 
                    direction={new Vector3(0, -1, -1)}
                    intensity={1.5} 
                    shadowMinZ={1} 
                    shadowMaxZ={2500}>
                    <shadowGenerator mapSize={1024} useBlurExponentialShadowMap={true} blurKernel={32} darkness={0.8} forceBackFacesOnly={true} depthScale={100} shadowCastChildren/>
                </directionalLight>
                <box 
                    name='skybox' 
                    size={1000} 
                    position={new Vector3(0, -30, 0)}>
                    <backgroundMaterial 
                        name='skybox' 
                        backFaceCulling={false}                        
                        ref={background_material}

                        // enableGroundProjection = {true}
                        // projectedGroundRadius={100}
                        // projectedGroundHeight={3}
                        // diffuseColor={new Color3(0, 0, 0)} 
                        // specularColor={new Color3(0, 0, 0)} 
                    />
                </box>      
                <box
                    name="contact_edit"
                    size={sticker_size}     
                    ref={contact_edit}          
                    enableEdgesRendering={true}
                    edgesColor={Color4.FromColor3(Color3.White(), 1)}     
                    edgesWidth={10}               
                    // material={contact_material.current!} // BABYLON BUG: not works
                >
                    <standardMaterial
                        name="contact_edit"
                        alpha={0}
                    />
                    <transformNode
                        name="lt"
                        position={new Vector3(sticker_size / 2, sticker_size / 2, sticker_size / 2)}
                    />
                </box>
                <box
                    name="contact_manage"
                    size={sticker_size}     
                    ref={contact_manage}          
                    enableEdgesRendering={true}
                    edgesColor={Color4.FromColor3(Color3.White(), 1)}     
                    edgesWidth={10}
                    // material={contact_material.current!} // BABYLON BUG: not works
                >
                    <standardMaterial
                        name="contact_manage"
                        alpha={0}
                    />
                    <transformNode
                        name="lt"
                        position={new Vector3(sticker_size / 2, sticker_size / 2, sticker_size / 2)}
                    />
                </box>
                <adtFullscreenUi
                    name='UI'                    
                >
                    <rectangle
                        name='background'
                        leftInPixels={0}
                        widthInPixels={scene?.getEngine().getRenderWidth()}
                        heightInPixels={scene?.getEngine().getRenderHeight()}
                    >
                    </rectangle>
                    {default_bg(
                        'sticker-edit', 
                        6, 
                        [
                            ['rotation', 'buttons_rotation'],
                            ['scale', 'buttons_scale'],
                            ['mirror', 'buttons_horizontal'],
                            ['check', 'buttons_complete'],
                            ['add', 'buttons_stamp'],
                            ['cancel', 'buttons_cancel'],
                        ].map((name_url, i) => (
                            <IconButton
                                name={name_url[0]}
                                length={6}
                                index={i}
                                icon={name_url[1]}
                                ssize={ssize}
                            />
                        )),
                        sticker_edit
                    )}
                    {default_bg(
                        'sticker-manage', 
                        1, 
                        [
                            ['cancel', 'buttons_cancel'],
                        ].map((name_url, i) => (
                            <IconButton 
                                name={name_url[0]}
                                length={1}
                                index={i} 
                                icon={name_url[1]}
                                ssize={ssize}
                            />
                        )),
                        sticker_manage
                    )}
                </adtFullscreenUi>
                <texture
                    url='logo512.png'                    
                />
                <dynamicTexture
                    name='text_canvas'
                    options={{width: 1024, height: 1024}}                    
                />
                <pbrMetallicRoughnessMaterial
                    name='sportsguard model'
                    backFaceCulling={true}
                    baseColor={new Color3(0.5, 0.5, 0.5)}                    
                    metallic={0.5}
                    roughness={1.0}        
                    baseTexture={base_texture.current!}            
                    metallicRoughnessTexture={mr_texture.current!}
                />

                <pbrMetallicRoughnessMaterial
                    name='sportsguard model original'
                    backFaceCulling={true}
                    
                    baseColor={new Color3(1.0, 1.0, 1.0)}
                    metallic={1.0}
                    roughness={1.0}        
                    baseTexture={base_texture.current!}            
                    metallicRoughnessTexture={mr_texture.current!}
                />


                <pbrMetallicRoughnessMaterial
                    alpha={0.4}
                    name='sportsguard cover'
                    backFaceCulling={true}
                    baseColor={new Color3(1.0, 1.0, 1.0)}
                    metallic={0.5}
                    roughness={0}
                    // zOffset={-1}
                    // zOffsetUnits={-1}
                    needDepthPrePass={true}
                    baseTexture={base_texture.current!}            
                />

                <pbrMaterial
                    alpha={1}
                    name='plasticMaterial'
                    backFaceCulling={false}                    
                    albedoColor = {new Color3(0.7, 0.7, 0.7)}                    
                    transparencyMode = {PBRMaterial.PBRMATERIAL_ALPHABLEND}
                    reflectivityColor = {new Color3(0.1, 0.1, 0.1)}                    
                    metallic={0.8}
                    roughness={0}
                    zOffset={-1}
                    // zOffsetUnits={-1}
                    needDepthPrePass={false}                 
                   
                    // baseTexture={base_texture.current!}            

                    onCreated={(material) => {
                        // material.subSurface.isScatteringEnabled = true;
                        // material.subSurface.isRefractionEnabled = true;
                        material.subSurface.indexOfRefraction = 1.49;
                        // material.subSurface.isTranslucencyEnabled = true;
                        material.subSurface.translucencyIntensity = 1.5;                                            
                        material.clearCoat.isEnabled = true;
                        material.clearCoat.intensity = 0.5;
                        material.alpha = 0.26;
                    }}
                />
                


                {/* leftColor to define color used on edges
                rightColor to define color used on center
                bias to define bias applied to computed fresnel term
                power to compute exponent applied to fresnel term

                finalFresnelTerm = pow(bias + fresnelTerm, power)*/}

                <standardMaterial
                    alpha={0.2}
                    name='splint'
                    backFaceCulling={false}
                    diffuseColor={new Color3(1.0, 1.0, 1.0)}
                    diffuseFresnelParameters={new FresnelParameters({
                        bias: 0.0,
                        power: 4,
                        leftColor: Color3.Gray(),
                        rightColor: Color3.White(),
                    })}
                    opacityFresnelParameters={new FresnelParameters({
                        bias: 0.0,
                        power: 4,
                        leftColor: Color3.White(),
                        rightColor: Color3.Black(),
                    })}
                    reflectionFresnelParameters={new FresnelParameters({
                        power: 4,
                        leftColor: Color3.Gray(),
                        rightColor: Color3.Black(),
                    })}
                >
                </standardMaterial>                

                <standardMaterial
                    alpha={0.99}
                    name='decal-preview'
                    backFaceCulling={false}
                    diffuseColor={new Color3(1.0, 1.0, 1.0)}
                >
                    <renderTargetTexture
                        name='decal-preview'
                        size={2048}
                        assignTo={'opacityTexture'}
                    >
                    </renderTargetTexture>
                </standardMaterial>                

                <standardMaterial
                    alpha={0.0}
                    name='decal-mask'
                    needDepthPrePass={true}
                >
                </standardMaterial>

                <pbrMetallicRoughnessMaterial
                    name='dracular'
                    backFaceCulling={true}
                    baseColor={new Color3(1.0, 1.0, 1.0)}
                    metallic={1.0}
                    roughness={1.2}
                >
                    <dynamicTexture
                        name='dracular_base_canvas'
                        options={{width: 1024, height: 1024}}
                        assignTo={'baseTexture'}
                    />
                    <dynamicTexture
                        name='mr_canvas'
                        options={{width: 1024, height: 1024}}     
                        assignTo={'metallicRoughnessTexture'}                                       
                    />
                </pbrMetallicRoughnessMaterial>
                <pbrMetallicRoughnessMaterial
                    name='attachment'
                    backFaceCulling={true}
                    baseColor={new Color3(1.0, 1.0, 1.0)}
                    metallic={1.0}
                    roughness={0.0}
                />
                <dynamicTexture
                    name='base_canvas'
                    options={{width: 1024, height: 1024}}                        
                    ref={base_texture}
                />
                <dynamicTexture
                    name='mr_canvas'
                    options={{width: 1024, height: 1024}}     
                    ref={mr_texture}                                       
                />
            </Scene>										
        </Engine>
    )
}