import { AbstractMesh, Epsilon, Matrix, Mesh, Vector3, VertexBuffer, VertexData } from "@babylonjs/core";
import { TRANSPARENT_ALPHA_INDEX } from "./state";

function updateFacetData(am: AbstractMesh) {
    const data = am._internalAbstractMeshDataInfo._facetData;
    if (!data.facetDataEnabled) {
        (am as any)['_initFacetData']();
    }
    const positions = am.getVerticesData(VertexBuffer.PositionKind);
    const indices = am.getIndices()!;
    const normals = am.getVerticesData(VertexBuffer.NormalKind);
    const bInfo = am.getBoundingInfo();
    if (data.facetDepthSort && !data.facetDepthSortEnabled) {
        // init arrays, matrix and sort function on first call
        data.facetDepthSortEnabled = true;
        if (indices instanceof Uint16Array) {
            data.depthSortedIndices = new Uint16Array(indices);
        }
        else if (indices instanceof Uint32Array) {
            data.depthSortedIndices = new Uint32Array(indices);
        }
        else {
            let needs32bits = false;
            for (let i = 0; i < indices.length; i++) {
                if (indices[i] > 65535) {
                    needs32bits = true;
                    break;
                }
            }
            if (needs32bits) {
                data.depthSortedIndices = new Uint32Array(indices);
            }
            else {
                data.depthSortedIndices = new Uint16Array(indices);
            }
        }
        data.facetDepthSortFunction = function (f1, f2) {
            return f2.sqDistance - f1.sqDistance;
        };
        if (!data.facetDepthSortFrom) {
            const camera = am.getScene().activeCamera;
            data.facetDepthSortFrom = camera ? camera.position : Vector3.Zero();
        }
        data.depthSortedFacets = [];
        for (let f = 0; f < data.facetNb; f++) {
            const depthSortedFacet = { ind: f * 3, sqDistance: 0.0 };
            data.depthSortedFacets.push(depthSortedFacet);
        }
        data.invertedMatrix = Matrix.Identity();
        data.facetDepthSortOrigin = Vector3.Zero();
    }
    data.bbSize.x = bInfo.maximum.x - bInfo.minimum.x > Epsilon ? bInfo.maximum.x - bInfo.minimum.x : Epsilon;
    data.bbSize.y = bInfo.maximum.y - bInfo.minimum.y > Epsilon ? bInfo.maximum.y - bInfo.minimum.y : Epsilon;
    data.bbSize.z = bInfo.maximum.z - bInfo.minimum.z > Epsilon ? bInfo.maximum.z - bInfo.minimum.z : Epsilon;
    let bbSizeMax = data.bbSize.x > data.bbSize.y ? data.bbSize.x : data.bbSize.y;
    bbSizeMax = bbSizeMax > data.bbSize.z ? bbSizeMax : data.bbSize.z;
    data.subDiv.max = data.partitioningSubdivisions;
    data.subDiv.X = Math.floor((data.subDiv.max * data.bbSize.x) / bbSizeMax); // adjust the number of subdivisions per axis
    data.subDiv.Y = Math.floor((data.subDiv.max * data.bbSize.y) / bbSizeMax); // according to each bbox size per axis
    data.subDiv.Z = Math.floor((data.subDiv.max * data.bbSize.z) / bbSizeMax);
    data.subDiv.X = data.subDiv.X < 1 ? 1 : data.subDiv.X; // at least one subdivision
    data.subDiv.Y = data.subDiv.Y < 1 ? 1 : data.subDiv.Y;
    data.subDiv.Z = data.subDiv.Z < 1 ? 1 : data.subDiv.Z;
    // set the parameters for ComputeNormals()
    data.facetParameters.facetNormals = am.getFacetLocalNormals();
    data.facetParameters.facetPositions = am.getFacetLocalPositions();
    data.facetParameters.facetPartitioning = am.getFacetLocalPartitioning();
    data.facetParameters.bInfo = bInfo;
    data.facetParameters.bbSize = data.bbSize;
    data.facetParameters.subDiv = data.subDiv;
    data.facetParameters.ratio = am.partitioningBBoxRatio;
    data.facetParameters.depthSort = data.facetDepthSort;
    if (data.facetDepthSort && data.facetDepthSortEnabled) {
        am.computeWorldMatrix(true);
        am._worldMatrix.invertToRef(data.invertedMatrix);
        Vector3.TransformCoordinatesToRef(data.facetDepthSortFrom, data.invertedMatrix, data.facetDepthSortOrigin);
        data.facetParameters.distanceTo = data.facetDepthSortOrigin;
    }
    data.facetParameters.depthSortedFacets = data.depthSortedFacets;
    if (normals) {
        VertexData.ComputeNormals(positions, indices, normals, data.facetParameters);
    }
    if (data.facetDepthSort && data.facetDepthSortEnabled) {
        data.depthSortedFacets.sort(data.facetDepthSortFunction);
        const l = (data.depthSortedIndices.length / 3) | 0;
        for (let f = 0; f < l; f++) {
            const sind = data.depthSortedFacets[f].ind;
            data.depthSortedIndices[f * 3] = indices[sind];
            data.depthSortedIndices[f * 3 + 1] = indices[sind + 1];
            data.depthSortedIndices[f * 3 + 2] = indices[sind + 2];
        }
        am.updateIndices(data.depthSortedIndices, undefined, true);
    }
    return am;
}

export class concave_transparent{
    model: Mesh;    
    facet_data_updated = 0;

    before_render = (mesh: AbstractMesh) => {
        if(mesh.isVisible && Date.now() > this.facet_data_updated + 100){
            // mesh.updateFacetData();
            updateFacetData(mesh)
            this.facet_data_updated = Date.now()
        }
    };

    constructor(public template: Mesh){
        this.model = new Mesh(`clone of ${template.name}`, template.getScene());       
        
        // important!! affect to decalbuilder
        this.model.overrideMaterialSideOrientation = template.overrideMaterialSideOrientation;
        // console.log(this.model.overrideMaterialSideOrientation);

        // why clone? why reserve template?
        // after updateFacetData, MeshBuilder.CreateDecal cannot find mesh for some reason. it looks like backface culling works opposite.
        // so we copy reserve original vertex data in template, to use on MeshBuilder.CreateDecal.

        const vertexData = VertexData.ExtractFromMesh(template, true, true);

        vertexData.applyToMesh(this.model, true);        
        this.model.parent = template.parent;
        this.model.position = template.position;
        this.model.rotationQuaternion = template.rotationQuaternion;
        this.model.scaling = template.scaling;
        this.model.mustDepthSortFacets = true;
        this.model.registerBeforeRender(this.before_render);
        this.model.alphaIndex = TRANSPARENT_ALPHA_INDEX;

        // template.dispose();
        this.template = template;
        this.template.isVisible = false;        
    }

    dispose(){
        this.model.unregisterBeforeRender(this.before_render);        
        this.model.disableFacetData();
        this.model.dispose();
    }
}
