import {useState, FC, useRef} from 'react';
import "@babylonjs/core/Physics/physicsEngineComponent"  // side-effect adds scene.enablePhysics function
import "@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent"; // side-effect for shadow generator
import '@babylonjs/loaders';
import '@fontsource-variable/inter';

import * as CANNON from 'cannon';
import Overlay from './overlay'
import context from './context';
import { BabylonView } from './BabylonView';
import { ILoadingScreen } from '@babylonjs/core';
import { model } from './models';
window.CANNON = CANNON;

const App: FC = () => {
	// console.log('App Rendered');
	const [context_ready, set_context_ready] = useState(false);
    const [loading, set_loading] = useState(false);
    const [env_index, set_env_index] = useState(0);

    const mix_image0 = useRef<HTMLImageElement>(null);
    const mix_image1 = useRef<HTMLImageElement>(null);
    const scratch_canvas = useRef<HTMLCanvasElement>(null);    
    const dracular_teeth = useRef<HTMLImageElement>(null);
    const dracular_topline = useRef<HTMLImageElement>(null);

    // console.log('context_ready', context_ready);

	if(!context_ready){
		const check_context = () => {
            if(context.scene){
				set_context_ready(true);
			}
			else{                
				setTimeout(check_context, 100);
			}
		};
		check_context();
	}
    else{        
        if(!context.scene){
            set_context_ready(false);
        }
    }

    model.mix_image0 = mix_image0.current!;
    model.mix_image1 = mix_image1.current!;
    model.scratch_canvas = scratch_canvas.current!;
    model.teeth = dracular_teeth.current!;
    model.topline = dracular_topline.current!;

    const loading_screen: ILoadingScreen = {        
        displayLoadingUI() {
            set_loading(true);
        },
        hideLoadingUI() {
            set_loading(false);
        },
        loadingUIBackgroundColor: 'white',
        loadingUIText: 'loading',
    };

    // const hide_babylonview = true;
    const hide_babylonview = false;

	return (
		<div 
			className="App" 			
			style={{position: 'absolute', width: '100%', height: '100%', overflow: 'hidden'}}
		>	
			<BabylonView
                loading_screen={loading_screen}
                env_index={env_index}
            />				
            {
                hide_babylonview &&
                <div 
                    style={{
                        position: 'absolute', 
                        width: '100%', 
                        height: '100%', 
                        backgroundColor: 'CadetBlue',
                    }}
                >                    
                </div>
            }
			{!!context_ready && 
            <Overlay
                loading={loading}
                change_env={() => set_env_index(env_index + 1)}
            />
            }
            <div 
                style={{display: 'none'}}
                key={'hidden'}
            >
                <img src='pattern/noise0.png' alt="noise0" ref={mix_image0}/>
                <canvas ref={scratch_canvas}/>
                <img src='pattern/noise1.png' alt="noise1" ref={mix_image1}/>
                <img src='pattern/dracular-teeth.png' alt="dracular-teeth" ref={dracular_teeth}/>
                <img src='pattern/dracular-line.png' alt="dracular-line" ref={dracular_topline}/>
            </div>
		</div>
	);
}

export default App;

