import { CSSProperties, MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import './App.css';
import { color_option, color_options, compare_mrcolor4, dracular_first_third_color_options, dracular_second_color_options, mrcolor4 } from './state';

const make_icon_url = (url: string) => {
    // this makes under css directory when hosted.
    // return `url(./${url})`;
    return `url(/${url})`;
}

export function Tab(props: { tabs: string[], selected: number, on_changed: (n: number) => void, children: ReactNode }){
    return <StickyHeader
        head={
            props.tabs.map((t, i) => (                        
                <button
                    key={props.tabs[i]}                               
                    onClick={() => {
                        if (i !== props.selected) {
                            props.on_changed(i);
                        }
                    }}
                    style={{
                        fontSize: '15px',              
                        borderRadius: '15px',
                        padding: '5px',          
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        backgroundColor: i === props.selected ? 'gold' : 'white',
                    }}
                >
                    {props.tabs[i]}
                </button>                    
            ))
        }
        body={props.children}
    />;
};

export function StickyHeader(props: {head: ReactNode, body: ReactNode }){
    return (
        <div className='tab'>
            <div 
                className='default-bg tab-head'
                style={{borderRadius: '25px'}}
            >
                {props.head}
            </div>
            <div 
                className='default-bg tab-body'
                style={{borderRadius: '25px'}}
            >
                {props.body}
            </div>
        </div>
    );
};

export function IconButton(props: { icon_name: string, on_click: MouseEventHandler<HTMLButtonElement>, invert?: boolean, children?: ReactNode }){
    const icon_p_url = make_icon_url(`icons/icon_${props.icon_name}_p.png`);
    const icon_n_url = make_icon_url(`icons/icon_${props.icon_name}_n.png`);

    return (
        <button
            className="icon-button"
            style={{
                '--normal_url': props.invert ? icon_p_url : icon_n_url,
                '--pressed_url': props.invert ? icon_n_url : icon_p_url,
            } as CSSProperties}
            onClick={props.on_click}
        >
            <img alt='./icons/icon_unknown_n.png' />
            {
                props.children
            }
        </button>
    );
};

export function SelectMaterial(props: { 
    icon_name: string, 
    on_click: MouseEventHandler<HTMLButtonElement>, 
    invert?: boolean, 
    children?: ReactNode 
}){

    const icon_p_url = make_icon_url(`icons/icon_${props.icon_name}_p.png`);
    const icon_n_url = make_icon_url(`icons/icon_${props.icon_name}_n.png`);

    return (
        <button
            className="icon-button"
            style={{
                '--normal_url': props.invert ? icon_p_url : icon_n_url,
                '--pressed_url': props.invert ? icon_n_url : icon_p_url,
            } as CSSProperties}
            onClick={props.on_click}
        >
            <img alt='./icons/icon_unknown_n.png' />
            {
                props.children
            }
        </button>
    );
};

export function SelectColor(prop: {
    init_color: mrcolor4,
    on_change: (color: mrcolor4) => void
    color_options: {[k: string]: color_option}  
}){

    const [displayColorPicker, set_displayColorPicker] = useState(false);
    const [color, set_color] = useState(prop.init_color);    
    const [clientXY, set_clientXY] = useState({clientX: 0, clientY: 0});
    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        set_clientXY(e);
        set_displayColorPicker(!displayColorPicker);
    };

    useEffect(() => {
        set_color(prop.init_color);
    }, [prop.init_color]);

    const handleClose = () => {
        set_displayColorPicker(false);
    };

    const handleChange = (c : mrcolor4) => {
        set_color(c);
        prop.on_change(c);
        handleClose();
    };

    const current_option_name = Object.keys(prop.color_options).find(k => 
        compare_mrcolor4(color, prop.color_options[k].mrc4)
    );

    return (
        <div 
            style={{display: 'contents'}}
        >
            <IconButton
                icon_name={current_option_name ? prop.color_options[current_option_name].icon : "unknown"}
                on_click={handleClick}
            >
            {
                displayColorPicker && 
                <div 
                    className='colorpicker-popup' 
                    style={{
                        '--clientX': `${clientXY.clientX}px`,
                        '--clientY': `${clientXY.clientY}px`,   
                        zIndex: 2
                    } as CSSProperties}
                >
                    <div 
                        className='cover' 
                        style={{zIndex: -1}} 
                        onClick={handleClose} 
                    />
                    {
                        Object.keys(prop.color_options).map(k => 
                            <IconButton
                                key={k}
                                icon_name={prop.color_options[k].icon}
                                on_click={() => handleChange(prop.color_options[k].mrc4)}
                            />
                        )
                    }
                </div>
            }
            </IconButton>            
        </div>
    )
}

type popup_desc = {
    [name: string]: (() => void) | popup_desc,
};

type popup_prop = {
    desc: popup_desc,
    depth: number,
}

export function TreeMenu(prop: popup_prop){
    const [submenu, set_submenu] = useState('');

    const keys = Object.keys(prop.desc);

    const menu_clicked = (key: string) => {
        const v = prop.desc[key];
        if(typeof v === 'function'){
            v();
        }
        else if(key !== submenu){
            set_submenu(key);
        }
        else if(key === submenu){
            set_submenu('');
        }
    };
    
    // self와 layerdpopup(서브메뉴) 의 key를 세팅하지 않으면 submenu-submenu 의 state가 클리어되지 않는다. 재현해볼 것.

    return (
        <>
            <div 
                key={'self'}
                // className={prop.root ? 'treemenu-root' : 'treemenu'}
                className={'treemenu'}
                style={{
                    '--depth': `${prop.depth}`,
                } as CSSProperties}
            >
            {
                keys.map(k => (
                    <button
                        key={k}
                        className='treemenu-button'
                        onClick={() => menu_clicked(k)}
                    >
                        {k}
                    </button>
                ))
            }
            </div>
            {
                prop.desc[submenu] && 
                // TODO: top & left offseting
                <TreeMenu
                    key={submenu}
                    desc={prop.desc[submenu] as any as popup_desc}
                    depth={prop.depth + 1}
                />
            }
        </>
    );
}

export function DefaultBG(props: {icon_button_bar?: boolean, children: ReactNode}){

    const style = {} as CSSProperties;
    if(props.icon_button_bar){
        style.padding = '0px 5px';
    }
    return (
        // <div
        //     style={{...style, position: 'relative'}}
        // >
        //     <div
        //         className="default-bg2"            
        //         style={{position: 'absolute', left: '-5px', right: '-5px'}}
        //     >
        //     </div>
        //     {props.children}
        // </div>
        <div
            className="default-bg"            
            style={style}
        >
            {props.children}
        </div>
    );
}

export function CheckIcon(icon: string, b: boolean, on_change: (b: boolean) => void){
    return (
        <DefaultBG>
            <IconButton             
                icon_name={icon}
                invert={b}
                on_click={() => on_change(!b)}
            />
        </DefaultBG>
    );
};

export function OptionsIcon(icons: Record<number, string>, none: number, n: number, on_change: (n: number) => void){
    let keys = Object.keys(icons);
    return (
        <DefaultBG>
        {
            keys.map(k => (
                <IconButton 
                    key={k}
                    icon_name={icons[+k]}
                    invert={+k === n}
                    on_click={() => on_change(+k === n ? none : +k)}
                />
            ))
        }
        </DefaultBG>
    );        
};    

export function EditColors(colors: mrcolor4[], on_change: (colors: mrcolor4[]) => void, available_colors?: (keyof typeof color_options)[]){
    return (
        <div className='default-bg'>
            {
                colors.map((c, i) => 
                    <SelectColor
                        key={i}
                        init_color={c}
                        on_change={c => {
                            const cs = [...colors];
                            cs[i] = c;
                            on_change(cs);
                        }}
                        color_options={
                            Object.fromEntries(
                                (available_colors || Object.keys(color_options)).map(key => [key, color_options[key]])
                            )
                        }
                    />
                )
            }
        </div>
    );
}

export function EditMaterials(icons: Record<number, string>, n: number, on_change: (n: number) => void){
    let keys = Object.keys(icons);
    return (
        <DefaultBG>
        {
            keys.map(k => (
                <SelectMaterial 
                    key={k}
                    icon_name={icons[+k]}
                    invert={+k === n}
                    on_click={() => on_change(+k)}
                />
            ))
        }
        </DefaultBG>
    );
};    

export function ChoicesIcon(icons: Record<number, string>, n: number, on_change: (n: number) => void){
    let keys = Object.keys(icons);
    return (
        <DefaultBG>
        {
            keys.map(k => (
                <IconButton 
                    key={k}
                    icon_name={icons[+k]}
                    invert={+k === n}
                    on_click={() => on_change(+k)}
                />
            ))
        }
        </DefaultBG>
    );
};    

export interface EditDracularColorsProps {
    colors: mrcolor4[];
    on_change: (colors: mrcolor4[]) => void;
}

export function EditDracularColors({ colors, on_change }: EditDracularColorsProps) {
    return (
        <div className='default-bg'>
            <SelectColor
                key="first"
                init_color={colors[0]}
                on_change={c => {
                    const cs = [...colors];
                    cs[0] = c;
                    on_change(cs);
                }}
                color_options={dracular_first_third_color_options}
            />
            <SelectColor
                key="second"
                init_color={colors[1]}
                on_change={c => {
                    const cs = [...colors];
                    cs[1] = c;
                    on_change(cs);
                }}
                color_options={dracular_second_color_options}
            />
            <SelectColor
                key="third"
                init_color={colors[2]}
                on_change={c => {
                    const cs = [...colors];
                    cs[2] = c;
                    on_change(cs);
                }}
                color_options={dracular_first_third_color_options}
            />
        </div>
    );
}

export * from './components/SaturationControl';